/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.alert-success { 
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-warning { 
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; 
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.mat-simple-snackbar-action button{
  color: #FFFFFF;
  background: #290604;
}

/*Color Variables*/

$color-primary: #009EE3;
$color-secondary: #0075BE;
$color-tertiary: #FFDD00;
$color-quaternary: #F7A600;


.clickeable {
  cursor: pointer;

  &:hover {
    background-color: $color-secondary;
  }
}


.sync-container {
  border-radius: 8px;
  margin: 10px;
  background-color: $color-tertiary;
  display: inline-flex;
  align-items: center;

  mat-icon {
    padding: 10px;
  }

  button {
    color: #FFFFFF;
    background-color: $color-primary;
    margin: 10px;
  }
}
